import React from 'react';
import styled from 'styled-components';
import Carousel from '@ui-elem/Carousel/Carousel';
import Banner from 'deco/Cms/Banner.jsx';
import { getThemeFactory } from '@ic-theme';
import PersonalizedProductComponentMod from './PersonalizedProductComponentMod';
import isSmartEditContainerPresent from 'core/util/smartedit';

const name = 'ComponentsContainer';

const th = getThemeFactory(name, { bannerMarginBottom: '0px' });

const renderComponentWithOutCarousel = (p) => {
  return p.items.map((item) => {
    if (item !== '') {
      if (item.componentType === 'PersonalizedProduct') {
        return <PersonalizedProductComponentMod key={item.componentType} {...item.properties} />;
      }
      return (
        <Banner
          elementUid={item.properties.elementUid}
          componentParentType={item.properties.parentType}
          contentSlotCatalogVersion={p.contentSlotCatalogVersion}
          SSRKey={item.properties.uid}
          key={item.properties.imgAltText}
          {...item.properties}
        />
      );
    }
    return null;
  });
};
export default class ComponentsContainer extends React.Component {
  static get propTypes() {
    return {};
  }

  // Can we remove jsx-parser from here, why do we need it?
  render() {
    const p = this.props;
    const theme = th();

    const StyledCarousel = styled.div`
      &&& {
        width: ${theme.desktopWidth ? theme.desktopWidth : '100%'};
        height: 100%;
        ${p.items && p.items.length > 1
          ? ' padding-bottom: 0;  @media (max-width: 580px) { padding-bottom: 10px !important;}'
          : ''};
        @media (max-width: 580px) {
          width: 100%;
        }
        @media (min-width: 600px) and (max-width: 900px) {
          width: ${theme.ipadWidth ? theme.ipadWidth : '100%'};
        }
        .slick-dots {
          ${theme.hideDots ? 'display:none !important' : ''}
        }
        div[class*='Carousel__StyledCarousel'] {
          ${theme.hideDots ? ` margin-bottom: ${theme.bannerMarginBottom} !important;padding-bottom: 0;` : ''}
        }
        .ic-cms-banner > div > div {
          padding-bottom: 0px !important;
        }
      }
    `;

    if (this.props.advanceEditFlag === true && isSmartEditContainerPresent()) {
      return <div data-ssr-key={this.props.SSRKey}>{renderComponentWithOutCarousel(p)}</div>;
    }
    return (
      <div data-ssr-key={this.props.SSRKey}>
        <StyledCarousel className={'components-container'}>
          <Carousel key={'in-comp-container'} autoPlay={theme.autoPlay ? theme.autoPlay : false}>
            {renderComponentWithOutCarousel(p)}
          </Carousel>
        </StyledCarousel>
      </div>
    );
  }
}
