import React from 'react';
import logger from 'score/logSvc';
import cloneDeep from 'fast-clone';
import Button from '@ui-elem/Button/Button';
import { setupInitialState, setupMutations, superConnect, mapItems } from 'core/util/module-utils';
import * as cls from './PersonalizedProductComponent.styles';
import { getThemeFactory } from '@ic-theme';
import { str } from 'seco/localeSvc';
import Link from 'ic/ui-elem/Link';
import { getReducer } from '@spa-core/legacy-adapter/utils';
import { NAME as appReducerName } from '@spa-core/store/app/constants';

const name = 'PersonalizedProductComponent';
const log = logger.getLogger(name);
const multipleInstances = false;

const th = getThemeFactory(name, {});

// Modules data, this is the initial data for an instance
const initialState = {};

const conf = { multipleInstances, name, initialState };

// ################# GETTERS  #################
export const getters = (state, ownProps) => {
  const instance = cloneDeep(mapItems(state, conf, ownProps.iid));
  return instance;
};

// ################# ACTIONS  #################

export const actions = (_dispatch, _ownProps) => ({});

// ################# MUTATIONS  #################

const mutations = {};

// ################# MODULE SETUP DON T TOUCH  #################
export const _module = {
  name, state: setupInitialState(initialState, conf), getters, actions, mutations: setupMutations(mutations, conf) // eslint-disable-line
};

export const PromoProductCard = (p) => (
  <div className="p-2 content-bkg w-full mx-2 flex lg:flex-col lg:justify-evenly lg:items-center">
    <div className="imageHolder relative">
      <img src={p.imageUrl} alt={p.description} />
      {p.imageUrl && p.isEnkrona ? (
        <img
          className={cls.oneKronaSticker}
          src={
            p.theme.oneKrSticker
              ? `${getReducer(appReducerName).sessionConfig.themeResourcePath}/${p.theme.oneKrSticker.rootPath}${
                  p.theme.oneKrSticker.sticker[p.country]
                    ? p.theme.oneKrSticker.sticker[p.country]
                    : p.theme.oneKrSticker.sticker.default
                }`
              : ''
          }
        />
      ) : null}
    </div>
    <div className={`text-s1 text-center ${cls.info}`}>{p.description}</div>
    <div className={cls.info}>
      <Link to={`${getReducer(appReducerName).sessionConfig.urlPrefix}${p.url}`}>
        <Button className="my-5 py-2 px-4 mb-4 text-x1 font-bold" buttonText={str('button.text.clickHere')} />
      </Link>
    </div>
  </div>
);

class PersonalizedProductComponentMod extends React.Component {
  /**
   * propTypes
   * @property {String} component-id (Mandatory) identifier for the CMS Component.
   */
  static get propTypes() {
    return {};
  }

  componentDidMount() {}

  render() {
    const p = this.props;
    const theme = th();
    const sessionConfig = getReducer(appReducerName).sessionConfig;
    const currentCountry = sessionConfig?.countryIso?.toLowerCase() || '';
    return (
      <div className={`flex flex-col lg:flex-row ${cls['main-bkg']}`}>
        <div className="w-full lg:w-3/5 m-4">
          <div className={`text-x3 font-head font-bold ${cls.title}`}>{`${str('text.hi')} ${p.userName ? p.userName : '!'}`}</div>
          <div className="flex flex-row px-2 py-5">
            <div
              className={`${cls['speech-bubble-big']} w-2/5 text-center py-12 px-6`}
              dangerouslySetInnerHTML={{ __html: p.speechBubbleText1 }}
            />
            <div className="w-3/5 flex">
              <div className="thumb_man_image w-1/2">
                <img src="/medias/sys_master/hc9/h9a/9230973403166.png" />
              </div>
              <div className={`${cls['speech-bubble-small']} w-1/2 font-spec relative`}>
                <div
                  className={`${cls['speech-bubble-text-small']} px-4 py-10`}
                  dangerouslySetInnerHTML={{ __html: p.speechBubbleText2 }}
                />
              </div>
            </div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: p.legalText }} />
        </div>
        <div className="w-full lg:flex lg:w-2/5 my-4 mx-2 flex-row justify-evenly">
          {p.personalizedProducts
            ? p.personalizedProducts.map((prod) => (
                <PromoProductCard key={prod.typeCode} theme={theme} country={currentCountry} {...prod} />
              ))
            : null}
        </div>
      </div>
    );
  }
}

require("core/redux/reducer").registerModule(name, _module);require("@spa-core/redux/store").newModuleLoaded();
export default superConnect(_module, PersonalizedProductComponentMod);
